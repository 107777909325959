export const FETCH_PRODUCTS = "FETCH_PRODUCTS"
export const FETCH_PRODUCT = "FETCH_PRODUCT"
export const FETCH_CATEGORIES = "FETCH_CATEGORIES"
export const FETCH_SUB_CATEGORIES = "FETCH_SUB_CATEGORIES"
export const FETCH_USERS = "FETCH_USERS"
export const FETCH_ADMIN_USERS = "FETCH_ADMIN_USERS"
export const FETCH_USER_COUNT = "FETCH_USER_COUNT"
export const START_LOADING = "START_LOADING"
export const END_LOADING = "END_LOADING"
export const POST_PRODUCT = "POST_PRODUCT"
export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const POST_CATEGORY = "POST_CATEGORY"
export const POST_SUB_CATEGORY = "POST_SUB_CATEGORY"
export const AUTH = 'AUTH'
export const LOGOUT = 'LOGOUT'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const DELETE_SUB_CATEGORY = 'DELETE_SUB_CATEGORY'
export const  ADMIN_SIGNUP='ADMIN_SIGNUP'


