export const userInputs = [
  {
    id: 1,
    label: "Full Name",
    type: "text",
    placeholder: "Full Name",
    name: "fullName"
  },
  {
    id: 2,
    label: "Username",
    type: "text",
    placeholder: "Username",
    name: "username"
  },
  {
    id: 3,
    label: "Email",
    type: "email",
    placeholder: "Email",
    name: "email"
  },
  {
    id: 4,
    label: "Phone",
    type: "text",
    placeholder: "Mobile Number",
    name: "mobileNumber"
  },
  {
    id: 5,
    label: "Password",
    type: "password",
    name: "password"
  },
];

export const adminUserInputs = [
  {
    id: 1,
    label: "Admin Id",
    name: "adminId",
    type: "text",
    placeholder: "Admin Id",
  },
  {
    id: 2,
    label: "Emp Id",
    name: "empId",
    type: "text",
    placeholder: "Emp Id",
  },
  {
    id: 3,
    label: "Email",
    name: "email",
    type: "email",
    placeholder: "Email",
  },
  {
    id: 4,
    label: "Password",
    name: "password",
    type: "text",
  },
  {
    id: 5,
    label: "Mobile Number",
    name: "mobileNumber",
    type: "text",
    placeholder:'Mobile Number'
  },
  {
    id: 6,
    label: "Address",
    name: "address",
    type: "text",
    placeholder:'Address'
  },
];

export const productInputs = [
  {
    id: 1,
    name: "productName",
    label: "Product Name",
    type: "text",
    placeholder: "Product Name",
  },
  {
    id: 2,
    name: "description",
    label: "Description",
    type: "text",
    placeholder: "Description",
  },
  {
    id: 3,
    name: "price",
    label: "Price",
    type: "text",
    placeholder: "Price",
  },
  {
    id: 4,
    name: "stock",
    label: "Stock",
    type: "number",
    placeholder: "Stock",
  },
  {
    id: 5,
    name: "brand",
    label: "Brand",
    type: "text",
    placeholder: "Brand",
  },
];

export const categoryInputs = [
  {
    id: 1,
    name: "categoryName",
    label: "Category Name",
    type: "text",
    placeholder: "Category Name",
  },
  {
    id: 2,
    name: "description",
    label: "Description",
    type: "text",
    placeholder: "Description",
  },
];

export const subCategoryInputs = [
  {
    id: 1,
    name: "subCategoryName",
    label: "Sub Category Name",
    type: "text",
    placeholder: "Sub Category Name",
  },
  {
    id: 2,
    name: "description",
    label: "Description",
    type: "text",
    placeholder: "Description",
  },
];

export const billingInputs = [
  {
    id: 1,
    name: "orderId",
    label: "Order Id",
    type: "text",
    placeholder: "Order Id",
  },
  {
    id: 2,
    name: "amount",
    label: "Amount",
    type: "text",
    placeholder: "Amount",
  },
  {
    id: 3,
    name: "payStatus",
    label: "Payment Status",
    type: "text",
    placeholder: "Payment Status",
  },
  {
    id: 4,
    name: "customerId",
    label: "Customer Id",
    type: "text",
    placeholder: "Customer Id",
  },
  {
    id: 5,
    name: "billingAddress",
    label: "Billing Address",
    type: "text",
    placeholder: "Billing Address",
  },
  {
    id: 6,
    name: "billingDate",
    label: "Billing Date",
    type: "date",
  },
];

export const customerInputs = [
  {
    id: 1,
    name: "customerId",
    label: "Customer Id",
    type: "text",
    placeholder: "Customer Id",
  },
  {
    id: 2,
    name: "status",
    label: "Status",
    type: "text",
    placeholder: "Status",
  },
  {
    id: 3,
    name: "gender",
    label: "Gender",
    type: "text",
    placeholder: "Gender",
  },
  {
    id: 4,
    name: "dob",
    label: "Date of Birth",
    type: "date",
  },
  {
    id: 5,
    name: "occupation",
    label: "Occupation",
    type: "text",
    placeholder: "Occupation",
  },
  {
    id: 6,
    name: "primaryAddress",
    label: "Primary Address",
    type: "text",
    placeholder: "Primary Address",
  },
  {
    id: 7,
    name: "secondaryAddress",
    label: "Secondary Address",
    type: "text",
    placeholder: "Secondary Address",
  },
  {
    id: 8,
    name: "aadharNo",
    label: "Aadhar Number",
    type: "text",
    placeholder: "Aadhar Number",
  },
  {
    id: 9,
    name: "panNo",
    label: "PAN Number",
    type: "text",
    placeholder: "PAN Number",
  },
];
