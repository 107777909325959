import { DELETE_PRODUCT, END_LOADING, FETCH_PRODUCT, FETCH_PRODUCTS, POST_PRODUCT, START_LOADING, UPDATE_PRODUCT } from "../constants/actionTypes";


const productReducer = (state = { products: [], count: 0, isLoading: false, product: {} }, action) => {
    switch (action.type) {
        case FETCH_PRODUCTS:
            return { ...state, products: action.payload.data, count: action.payload.count };
        case FETCH_PRODUCT:
            return { ...state, product: action.payload.data };
        case POST_PRODUCT:
            return { ...state, products: [...state.products, action.payload] }
        case DELETE_PRODUCT:
            return { ...state, products: state.products.filter((product) => product._id !== action.payload) }
        case UPDATE_PRODUCT:
                return {
                    ...state,
                    products: state.products.map((product) => (product._id === action.payload._id ? action.payload : product)),
                };
        case START_LOADING:
            return { ...state, isLoading: true }
        case END_LOADING:
            return { ...state, isLoading: false }
        default:
            return state;
    }
}

export default productReducer