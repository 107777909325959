import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { subCategoryColumns } from "../../datatablesource";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubCategories, } from "../../actions/fetchData";
import { CircularProgress } from "@mui/material";
import { deleteSubCategory } from "../../actions/deleteData";

const Datatable = ({type}) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const columns = subCategoryColumns
  const [data, setData] = useState([]);
  const {subCategories, isLoading, count} = useSelector((state)=>state.category)
  

  useEffect(()=>{
    !subCategories.length && dispatch(getSubCategories())
  },[location])
  useEffect(()=>{
    subCategories?.forEach((p)=>{
      p["id"]=p["_id"]
    })
    setData(subCategories)
  },[isLoading])
  
  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
    dispatch(deleteSubCategory(id))
  };


  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={params.row.id} style={{ textDecoration: "none" }}>
              <div className="viewButton" style={{backgroundsColor:'#141D37', color:'#141D37'}}>View</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  return (
  <>
      <div className="datatable">
      <div className="datatableTitle">
        {type}
        <Link to={`/sub-categories/new`} style={{backgroundsColor:'#141D37', color:'#141D37'}} className="link">
          Add New
        </Link>
      </div>
    { isLoading? <CircularProgress/> :(
      <DataGrid
        className="datagrid"
        rows={data}
        columns={columns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />)}
    </div>
  </>
  );
};

export default Datatable;
