import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const Single = () => {
  useEffect(()=>{
    if(!state)
       navigate(-1)
    else
      console.log("HHHHHH")
  },[])
  const params = useParams()
  const state = useSelector((state)=>params.userId?state.users.users.find((e)=>params.userId===e._id):state.products.products.find((e)=>params.productId===e._id)) 
  const navigate = useNavigate()
  const location = useLocation()
  const check = state.fullName?true:false
  console.log(state)
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <div className="editButton" onClick={() => navigate(`/products/edit/${state._id}`)} >Edit</div>
            <h1 className="title">Information</h1>
            <div className="item">
              <img
                src={state.productImages[0].url || "https://www.iconpacks.net/icons/2/free-icon-user-4253.png"}
                alt=""
                className="itemImg"
              />
              <div className="details">
                <h1 className="itemTitle">{state.fullName || state.productName}</h1>
                {!check && <div className="detailItem">
                  <span className="itemKey">Featured :</span>
                  <span className="itemValue">{state.featured}</span>
                </div> }
                <div className="detailItem">
                  <span className="itemKey">{check?"Email":"Brand"}:</span>
                  <span className="itemValue">{state.email || state.brand}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">{check? "Email Verified":"Availability"}:</span>
                  <span className="itemValue">{state.verified?.toString() || state.availability}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">{check?"Phone":"Category"}:</span>
                  <span className="itemValue">{state.mobileNumber || state.category}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">{check?"Mobile Verified":"Monthly Cost"}:</span>
                  <span className="itemValue">{state.mobileVerified?.toString() || state.costPerMonth}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">{check?"username":"Weekly Cost"}:</span>
                  <span className="itemValue">
                    {state.username || state.costPerWeek}
                  </span>
                </div>
                {!check && <>
                  <div className="detailItem">
                  <span className="itemKey">Description:</span>
                  <span className="itemValue">{state.description}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Stock:</span>
                  <span className="itemValue">{state.noOfStock}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Status:</span>
                  <span className="itemValue">{state.status}</span>
                </div>
                </>}
              </div>
            </div>
          </div>
          <div className="right">
            <Chart aspect={check?2.4/1:1.55 / 1} title={`${check?"User Spending":"Product Earning"} ( Last 6 Months)`} />
          </div>
        </div>
        <div className="bottom">
        <h1 className="title">Last Transactions</h1>
          <List/>
        </div>
      </div>
    </div>
  );
};

export default Single;
