import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from "react";
import FileBase64 from 'react-file-base64';
import { useDispatch, useSelector } from "react-redux";
import { postProduct, updateProduct } from "../../actions/postData";
import { getCategories, getProduct, getSubCategories } from "../../actions/fetchData";
import { useParams, useNavigate } from "react-router-dom";
import { CircularProgress, IconButton, NativeSelect } from "@mui/material";

const EditProduct = ({ inputs, title }) => {
    const { productId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState({
        productName: "",
        availability: "In Stock",
        category: "Other",
        subCategory: "Other",
        address: "",
        noOfStock: "",
        costPerWeek: "",
        costPerMonth: "",
        productImages: [],
        description: "",
        status: "Active",
        brand: "",
        featured: "yes",
    });
    const [isEditing, setIsEditing] = useState(false);
    const { categories, subCategories, isLoading } = useSelector((state) => state.category);
    const { product } = useSelector((state) => state.products);

    useEffect(() => {
        dispatch(getProduct(productId));
        setIsEditing(true);
        if (!categories.length) dispatch(getCategories());
        if (!subCategories.length) dispatch(getSubCategories());
    }, [productId]);

    useEffect(() => {
        setData(product);
    }, [product]);

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleImageDelete = (item) => {

        setData({ ...data, productImages: data.productImages.filter((i)=>i!==item) })
        // console.log(data.productImages)
      }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isEditing) {
            dispatch(updateProduct(productId, data));
        } else {
            dispatch(postProduct(data));
        }
        navigate("/products");
    };

    return (
        isLoading ? <CircularProgress /> :
            <div className="new">
                <Sidebar />
                <div className="newContainer">
                    <Navbar />
                    <div className="top">
                        <h1>{title}</h1>
                    </div>
                    <div className="bottom">
                        <div className="left">
                            {data.productImages && data.productImages.length > 0 ? (
                                data.productImages.map((p, index) => (
                                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <img
                                            src={p?.url || p}
                                            alt={`product-image-${index}`}
                                        />
                                        <IconButton color="error" onClick={() => handleImageDelete(p)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                ))
                            ) : (
                                <img
                                    src="https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                                    alt="no-image"
                                />
                            )}
                        </div>

                        <div className="right_S">
                            <form onSubmit={handleSubmit}>
                                <div className="formInput">
                                    <label htmlFor="file">
                                        Image: <DriveFolderUploadOutlinedIcon className="icon" />
                                    </label>
                                    <FileBase64
                                        type='file'
                                        multiple={false}
                                        onDone={({ base64 }) => setData({ ...data, productImages: [base64, ...data.productImages] })}
                                    />
                                </div>
                                {inputs.map((input) => (
                                    <div className="formInput" key={input.id}>
                                        <label>{input.label}</label>
                                        <input
                                            name={input.name}
                                            value={data[input.name]}
                                            type={input.type}
                                            placeholder={input.placeholder}
                                            onChange={handleChange}
                                        />
                                    </div>
                                ))}
                                <button type='submit'>{isEditing ? "Save" : "Send"}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default EditProduct;
