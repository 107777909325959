import * as api from '../api';
import { POST_CATEGORY, POST_PRODUCT, POST_SUB_CATEGORY, UPDATE_PRODUCT } from '../constants/actionTypes';

export const postProduct = (product,setData) => async(dispatch) => {
    try{
        const {data} = await api.postProduct(product);
        console.log(data)
        dispatch({type:POST_PRODUCT,payload:data});
        // We store the data in payload
    }
    catch(err)
    {
        console.log(err);
        alert(err.response.data.message)
        setData(product)
    }
}

export const postCategory = (category,setData) => async(dispatch) => {
    try{
        const {data} = await api.postCategory(category);
        console.log(data)
        dispatch({type:POST_CATEGORY,payload:data});
    }
    catch(err)
    {
        console.log(err);
        alert(err.response.data.message)
        setData(category)
    }
}

export const postSubCategory = (category,setData) => async(dispatch) => {
    try{
        const {data} = await api.postSubCategory(category);
        console.log(data)
        dispatch({type:POST_SUB_CATEGORY,payload:data});
    }
    catch(err)
    {
        console.log(err);
        alert(err.response.data.message)
        setData(category)
    }
}

export const updateProduct = (id, product, navigate) => async (dispatch) => {
    try {
        const { data } = await api.updateProduct(id, product);
        dispatch({ type: UPDATE_PRODUCT, payload: data });
        navigate('/products');
    } catch (err) {
        console.log(err);
        alert(err.response.data.message);
    }
};
